<template>
<div>
	<div class="ryokin-link" v-show="disp_flg">
		<v-btn 
		text
		@click="clickDownload"
		class="post-link">{{title}}</v-btn>
	</div>
	<div v-show="!disp_flg">
		{{not_title}}
	</div>
</div>
</template>
<style scoped>

.post-link {
	color:#1976d2;
  display: inline-block;
  word-break: break-all;
  padding: 2px 8px;
  border-radius: 5px;
  text-transform: none !important;
  white-space: normal;
  max-width:500px; 
  height: inherit !important;
}
</style>
<script>
import {CONSTANT} from '../constant';
export default {
	props:{
		/**
		 * plant_id
		 */
		plant_id:{
			type:Number,
			default:0,
			deep:true,
		},
		/**
		 * pdf_flg を無視してファイルが存在すれば、ダウンロード可能とする
		 */
		ignore_flg:{
			type:String,
			default:"false",
		},
		title:{
			type:String,
			default:'料金表はこちら'
		},
		not_title:{
			type:String,
			default:'料金表なし'
		},
	},
	data:()=>({
		pdf_file:'',
		disp_flg:false,
	}),
	watch:{
		plant_id: function (newPlant_id, oldPlant_id){
			this.pdf_file='';
			this.disp_flg = false;
			if(newPlant_id){
				this.axios.post('ryokin-file-download',{plant_id:newPlant_id})
				.then((res)=>{
					if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
						this.pdf_file = res.data.resultData.file;
						if(res.data.resultData.pdf_flg==1){
							this.disp_flg = true;
						}else{
							if(this.ignore_flg=="true"){
								this.disp_flg = true;
							}else{
								this.disp_flg = false;
							}
						}
					}
				});
			}
		}
	},
	methods: {
		clickDownload(){
			var blob = this.toBlob(this.pdf_file, 'application/pdf')

			var filename = '料金表.pdf'; 
			var link = document.createElement('a');
			document.body.appendChild(link);
			link.href = URL.createObjectURL(blob);
			link.download = filename; 
			link.click();
			document.body.removeChild(link);
		},
    	toBlob(data64, mime_ctype) {
			var bin = atob(data64.replace(/^.*,/, ''));
			var buffer = new Uint8Array(bin.length);
			for (var i = 0; i < bin.length; i++) {
				buffer[i] = bin.charCodeAt(i);
			}
			// Blobを作成
			try {
				var blob = new Blob([buffer.buffer], {
					type: mime_ctype,
				});
			} catch (e) {
				return false;
			}
			return blob;
    	},
	},

}
</script>
