<template>
    <v-dialog eager v-model="dialog" persistent width="800px">
        <v-card class="pb-5">
            <v-toolbar color="primary" height="40" dark>
                <v-toolbar-title>プラント地図情報</v-toolbar-title>
                <v-spacer />
                <v-icon @click="clickClose">mdi-close</v-icon>
            </v-toolbar>
            <v-card class="ma-5">
                <div id="map" class="map-area"></div>
            </v-card>
            <div class="d-inline-flex">
                <div class="pa-3">緯度経度</div>
                <v-text-field
                    readonly
                    class="ma-2 input-ladlng"
                    label="緯度"
                    v-model="lat"
                    outlined
                    hide-details=""
                    dense
                />
                <v-text-field
                    readonly
                    class="ma-2 input-ladlng"
                    label="経度"
                    v-model="lng"
                    outlined
                    hide-details=""
                    dense
                />
                <!-- <template v-if="!updateFlg">
                    <v-btn class="btn-edit ma-2" @click="clickEdit"
                        >位置を修正する</v-btn
                    >
                </template> -->
                <template>
                    <div class="ma-2">
                        地図上で正確な位置をマウスでクリックしてください
                    </div>
                </template>
            </div>
            <v-card class="ma-5 text-right" elevation="0">
                <v-btn class="ma-2 btn-edit" @click="clickOk" v-if="updateFlg">
                    決定
                </v-btn>
                <v-btn class="ma-2 btn-close" @click="clickClose">
                    閉じる
                </v-btn>
            </v-card>
        </v-card>
    </v-dialog>
</template>
<style lang="scss" >
.map-area {
    height: 500px;
}
.input-ladlng {
    border: 1px solid #ccc;
    padding: 5px;
    width: 130px;
    border-radius: 5px;
}
</style>
<script>
import "leaflet/dist/leaflet.css";
import L, { Draggable } from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
    components: {},
    props: {
        dlgCallBack: {
            type: Function,
            require: true,
        },
    },
    computed: {},
    data: () => ({
        dialog: false,
        url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
        url2: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
        zoom: 8,
        center: [35.6915299,139.7735556],

        updateFlg: false,

        lat: "",
        lng: "",
        orgLat: "",
        orgLng: "",

        icon_red: {},
        icon_yellow: {},
        addMarker: null,
        layer: {},
        map: {},
    }),
    mounted() {

        this.icon_yellow = L.icon({
            iconUrl: "/img/marker-yellow-icon.png",
            iconRetinaUrl: "/img/marker-yellow-icon-2x.png",
            shadowUrl: "/img/marker-shadow.png",
        });

        this.icon_red = L.icon({
            iconUrl: "/img/marker-red-icon.png",
            iconRetinaUrl: "/img/marker-red-icon-2x.png",
            shadowUrl: "/img/marker-shadow.png",
        });
    },
    methods: {
        /**
         * ダイアログのオープン
         * contList コンテナ一覧
         * openMode S:全検索 I:貸出中は選択不可にする
         */
        open(latlng) {
            this.updateFlg = true;
            this.dialog = true;
            this.$nextTick(
                () => {
                    var geoFlg = false;
                    if (latlng.lat !== null && latlng.lat !== '') {
                        geoFlg = true;
                        this.lat = latlng.lat;
                        this.lng = latlng.lng; 

                    }else{
                        this.lat = 35.6915299
                        this.lng = 139.773555
                    }
                        
                    this.layer = new L.tileLayer(this.url);
                    this.map = new L.Map("map", {
                        center: L.latLng(this.lat, this.lng),
                        zoom: 15,
                    });
                    if (geoFlg) {

                        var mak = L.marker(
                            { lat: this.lat, lng: this.lng },
                            { title: "aaa" }
                        ).addTo(this.map);
                        mak.setIcon(this.icon_red);
                    }
                    this.layer.addTo(this.map);
                    this.orgLat = this.lat = latlng.lat;
                    this.orgLng = this.lng = latlng.lng;

                    this.map.on("click", this.clickMap);
                },
            );
        },
        /**
         * 決定ボタン
         */
        clickOk(){

            this.dlgCallBack({ lat: this.lat, lng: this.lng});
            this.dialog = false;
            this.mapRemove();
        },
        /**
         * 終了処理 
         */
        clickClose() {
            this.dlgCallBack({ lat: this.orgLat, lng: this.orgLng});
            this.dialog = false;
            this.mapRemove();
        },
        /**
         * Mapの削除
         */
        mapRemove(){

            this.map.off();
            this.map.remove();
        },
        clickEdit() {
            this.updateFlg = true;
            this.map.on("click", this.clickMap);
        },
        clickMap(e) {
            if (this.addMarker) {
                this.map.removeLayer(this.addMarker);
                this.addMarker = null;
            }

            this.addMarker = L.marker(e.latlng, { title: "aaaa" }).addTo(
                this.map
            );
            this.addMarker.setIcon(this.icon_yellow);
            this.lat =
                Math.round((e.latlng.lat + Number.EPSILON) * 10000000) /
                10000000;
            this.lng =
                Math.round((e.latlng.lng + Number.EPSILON) * 10000000) /
                10000000;
        },
    },
};
</script>s