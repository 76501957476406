<template>
<div>
	<v-tabs
		v-model="tabValue"
		@change="changeTab"
		show-arrows>
			<v-tab
			v-for="category in categorys"
			:key="category.category_id"
			>{{category.category_name}}

			</v-tab>
		</v-tabs>
		<div class="category">
			<v-simple-table class="ma-5 datatable">
				<colgroup>
				<col style="width:80px;" />
				<col style="width:120px;" />
				<col style="width:120px;" />
				<col style="width:auto;" />
				</colgroup>
				<tr>
					<th>No</th>
					<th>日付</th>
					<th>種別</th>
					<th>ファイル・リンク</th>
				</tr>
				<tr v-for="(row, index) in siryos" :key="row.siryo_id">
					<td>{{index+1}}</td>
					<td>{{dateFormat(row.open_date)}}</td>
					<td>
						<template v-if="row.siryo_kbn===1">ダウンロード</template>
						<template v-if="row.siryo_kbn===2">リンク</template>
					</td>
					<td>
						<template v-if="row.siryo_kbn===1">
							<v-btn text @click="clickDownload(row)" class="post-link">
							{{row.siryo_title}}
							</v-btn>
						</template>
						<template v-if="row.siryo_kbn===2">
							<v-btn
								:href="row.siryo_url"
								target="_blank"
							 text  class="post-link">
							{{row.siryo_title}}
							</v-btn>
						</template>
					</td>
				</tr>
			</v-simple-table>
		</div>
</div>
</template>
<style lang="scss" scoped>
.post-link {
  color:#1976d2;
  display: inline-block;
  word-break: break-all;
  padding: 2px 8px;
  border-radius: 5px;
  text-transform: none !important;
  white-space: normal;
  max-width:500px; 
  height: inherit !important;
}
</style>
<script>
import {CONSTANT} from '../constant';
export default {
	data() {
		return {
			categorys:[],
			siryos:[],
			orgSiryos:[],
			tabValue:0,		
		}
	},
	mounted(){
		console.log("mount")
		this.getSiryo();
	},
	methods: {
        getSiryo(){
            this.axios.post("/member/siryo/get-siryo-list",{disp_type:'1'}).then((res)=>{
                if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
					this.categorys = res.data.resultData.categorys;
					this.orgSiryos = res.data.resultData.siryos;
					this.filterSiryo(this.tabValue);
                }
            })
        },
		/**
		 * タブの切替イベント
		 * @param {タブのID} tabNo 
		 */
		changeTab(tabNo){
			console.log("chgtab=" + tabNo)
			this.filterSiryo(tabNo);
		},
		/**
		 *タブ切り替え時のリスト表示処理 
		 * @param {タブ番号} tabNo 
		 */
		filterSiryo(tabNo){
			this.siryos = [];

			const category_id = this.categorys[tabNo].category_id;
			this.siryos = this.orgSiryos.filter(function(item){
				return item.category_id == category_id;
			})
		},
		/**
		 * ダウンロードボタンクリック 
		 * @param {資料情報} siryo 
		 */
		async clickDownload(siryo){
			const res = await this.axios.post('/member/siryo/get-siryo-file',{siryo_id:siryo.siryo_id});
			if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
				const blob = this.toBlob(res.data.resultData.siryo_base64_file, res.data.resultData.siryo_file_mime_type);
				const fileName = res.data.resultData.siryo_dl_name;
				var link = document.createElement('a');
				document.body.appendChild(link);
				link.href = URL.createObjectURL(blob);
				link.target = '_blank';
				link.download = fileName; 
				link.click();
				document.body.removeChild(link);

			}
		},
    	toBlob(data64, mime_ctype) {
			var bin = atob(data64.replace(/^.*,/, ''));
			var buffer = new Uint8Array(bin.length);
			for (var i = 0; i < bin.length; i++) {
				buffer[i] = bin.charCodeAt(i);
			}
			// Blobを作成
			try {
				var blob = new Blob([buffer.buffer], {
					type: mime_ctype,
				});
			} catch (e) {
				return false;
			}
			return blob;
    	},

		
	},
}
</script>