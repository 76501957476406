import { render, staticRenderFns } from "./SiryoDownload.vue?vue&type=template&id=2c8f525f&scoped=true&"
import script from "./SiryoDownload.vue?vue&type=script&lang=js&"
export * from "./SiryoDownload.vue?vue&type=script&lang=js&"
import style0 from "./SiryoDownload.vue?vue&type=style&index=0&id=2c8f525f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c8f525f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VSimpleTable,VTab,VTabs})
