<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="pb-5">
            <v-toolbar color="primary" height="40" dark>
                <v-toolbar-title>実績ダイアログ</v-toolbar-title>
                <v-spacer />
                <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-toolbar>
            <v-card class="ma-5">
				<table class="plant-tbl" style="width:100%">
                    <col width="180px">
					<tr>
						<th>年度
<span class="required">(必須)</span>
                        </th>
						<td>
							<div v-if="mode=='UP'" class="d-inline-flex">
                                <div style="width:90px">
                            <v-text-field
                                maxlength="100"
                                class="ml-3"
                                style="width: 360px"
                                v-model="nendo"
								readonly
								background-color="blue-grey lighten-5"
                                :error="isError(err.nendo)"
                                solo
                                dense
                                outlined
                                hide-details=""
                            />
                                </div>
							</div>
							<div v-if="mode=='ADD'" class="d-inline-flex">
                                <div style="width:90px">
                            <v-text-field
                                maxlength="4"
                                width="30"
                                type="number"
                                class="mr-3"
                                :error="isError(err.nendo)"
                                style="width: 360px"
                                v-model="nendo"
                                solo
                                dense
                                outlined
                                hide-details=""
                            />
                                </div>
                            <div class="txt-center ml-3">年
                                &nbsp;&nbsp;例）2022
                            </div>
							</div>

                            <div class="controll-error ml-2"
                            v-show="isError(err.nendo)">
                                <span v-html="dispError(err.nendo)"></span>
                            </div>
						</td>
					</tr>
					<tr>
						<th>発生土受入量
                            <span class="required">(必須)</span>

                        </th>
						<td>
                            <div class="d-inline-flex">
                                <dvi style="width:120px">
                                <v-text-field
                                    type="number"
                                    class="mr-3"
                                    style="width: 360px"
                                    v-model="hassei_gendo"
                                    :error="isError(err.hassei_gendo)"
                                    solo
                                    dense
                                    outlined
                                    hide-details=""
                                />
                                </dvi>
                            <div class="ml-3 txt-center">
                            万地山 m3
                            </div>
                            </div>
                            <div class="controll-error ml-2"
                            v-show="isError(err.hassei_gendo)">
                                <span v-html="dispError(err.hassei_gendo)"></span>
                            </div>

						</td>
					</tr>
					<tr>
						<th>改良土出荷量
                            <span class="required">(必須)</span>

                        </th>
						<td>
                            <div class="d-inline-flex">
                                <div style="width:120px">
                                    <v-text-field
                                        maxlength="6"
                                        type="number"
                                        class="mr-3"
                                        :error="isError(err.shuka_kairyou)"
                                        style="width: 360px"
                                        v-model="shuka_kairyou"
                                        solo
                                        dense
                                        outlined
                                        hide-details=""
                                    />
                                </div>
                            <div class="mx-3 txt-center">
                            万ほぐし m3
                            </div>
                            </div>
                            <div class="controll-error ml-2"
                            v-show="isError(err.shuka_kairyou)">
                                <span v-html="dispError(err.shuka_kairyou)"></span>
                            </div>

						</td>
					</tr>
				</table>
            </v-card>
            <v-card class="ma-5 text-right" elevation="0">
                <v-btn class="btn-edit mx-3"  @click="clickEnter"> 設定 </v-btn>
                <v-btn class="btn-close mx-3" @click="dialog = false"> 閉じる </v-btn>
            </v-card>
        </v-card>
    </v-dialog>
</template>
<script>
import {mapState} from 'vuex'
import { MESSAGE } from '../../messages';
export default {
    components: {},
    props:{
		callback:{
			type:Function
		}
    },
    computed:{
    },
    
    data: () => ({
        dialog: false,
		mode:'',
		nendo:'',
		shuka_kairyou:'',
		hassei_gendo:'',
		jituseki:{},
        nen:2021,
        err:{
            nendo:'',
            shuka_kairyou:'',
            hassei_gendo:'',
        }
    }),
    mounted(){
    },
    methods: {
		open(mode,jituseki, nendo='',hassei_gendo='',shuka_kairyou=''){
			this.mode = mode;
			this.nendo= nendo;
			this.jituseki = jituseki;
			this.hassei_gendo = hassei_gendo;
			this.shuka_kairyou = shuka_kairyou;
			this.dialog = true;
		},
        async clickEnter(){

            //error clear
            this.clearError();

            if(await this.inputCheck()){
                // in error
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;

            }
			if(this.mode=='ADD'){
					
				for(let i in this.jituseki){
					if(this.jituseki[i].nendo==this.nendo){
						this.swalAlert(MESSAGE.TITLE_ERROR,MESSAGE.E007);
						return;
					}
				}
			}
			this.callback(this.nendo,this.hassei_gendo,this.shuka_kairyou);
            this.dialog = false;
        },
        /**
         * 入力チェック
         */
        inputCheck(){
            if(this.nendo === ""){
                this.err.nendo = [MESSAGE.formatByArr(MESSAGE.E001,'年度')];
            }
            if(this.hassei_gendo === ""){
                this.err.hassei_gendo = [MESSAGE.formatByArr(MESSAGE.E001,'発生土受入量')];
            }
            if(this.shuka_kairyou === ""){
                this.err.shuka_kairyou = [MESSAGE.formatByArr(MESSAGE.E001,'改良土出荷量')];
            }

            for (let key in this.err) {
                if (this.err[key].length > 0){
                    return true;
                }
            }
            return false;
        },
		clickClose(){
			this.dialog = false;
		},
        /**
         * エラーの一括クリア
         */
        clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
        },
    },
};
</script>
