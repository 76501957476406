<template>
    <v-container>
       
        <v-card width="400px" class="mx-auto mt-5">
            <v-card-title>
                <h1 class="display-1">会員Login</h1>
            </v-card-title>
        
            <v-card-text>
                <v-form>
                    <v-text-field
                        v-model="username"
                        prepend-icon="mdi-account-circle"
                        label="会員ID"
                        v-on:keyup.enter="login"
                        :error="userMsg.length>0 || errFlg"
                        :error-messages="userMsg"
                        maxlength="30"
                    />
                    <v-text-field
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        prepend-icon="mdi-lock"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        label="パスワード"
                        v-on:keyup.enter="login"
                        :error="passMsg.length>0 || errFlg"
                        :error-messages="passMsg"
                        maxlength="30"
                    />
                    <v-card-actions>
                        <v-btn class="info" @click="login">ログイン</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
        </v-card>
        <div style="width: 400px;" class="d-block mt-5 mx-auto">パスワードを忘れたときは<a href="javascript:void(0)" class="pass-forgot" @click="clickForgot()">こちら</a></div>
        <dlg-forgot-password ref="refDlgForgotPassword" :dlg-callback="dlgCallback"></dlg-forgot-password>
        <v-alert
            :value="alert"
            type="error"
            text
            outlined
            dismissible
            class="error-alert"
            elevation="3"
            transition="slide-y-transition"
        >    
            ユーザーIDもしくはパスワードが違います。
        </v-alert>
    </v-container>
</template>

<style scoped>
.error-alert {
    position: relative;
    background-color: #fff !important;
    z-index:1;
    left:50%;
    top:50px;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    width:500px;
}
</style>

<script>
import { MESSAGE } from "../messages";
import DlgForgotPassword from '../components/dialog/DlgForgotPassword.vue';
export default {
    components:{DlgForgotPassword},
	name: 'Login',
	data(){
		return {
			username:'',
			password:'',
			showPassword:false,
            userMsg: [],
            passMsg:[],
            alert: false,
            errFlg: false,
		}
	},
    async created(){
        await this.axios.get('member/system-check'); 
    },
	methods: {
		login: function () {
            this.alert = false;
            this.errFlg = false;
            this.userMsg = [];
            this.passMsg  = [];
            if(this.username == ''){
               this.userMsg.push('ユーザー名を入力してください。')
            }
            if(this.password == ''){
               this.passMsg.push('パスワードを入力してください。')
            }
            if(this.userMsg.length > 0 || this.passMsg.length > 0) return;

			const postData = {
				userid: this.username,
				password: this.password,
			}

			this.axios.post("/login-member", postData).then((res)=>{
                if(res.data.resultCode == 0){
                    this.$store.commit('setToken', res.data.resultData.token);
                    this.$store.commit('setMemberId', res.data.resultData.member_id);
                    this.$store.commit('setMemberKbn', res.data.resultData.member_kbn);
                    this.$router.push("/")
                }else{
                    this.alert = true;
                    this.username = '';
                    this.password = '';
                    this.errFlg = true;
                }
            })
        },
        async clickForgot(){
                this.$refs.refDlgForgotPassword.open();
        },
        dlgCallback(){
        },
	}
}
</script>