<template>
    <v-container fluid class="mt-0 pa-0">
        <v-sheet
            elevation="2"
            height="40"
            class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-4"
        >
            <v-icon class="white--text mb-2">mdi-database</v-icon>
            プラント管理
        </v-sheet>
        <v-card class="ma-5">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    プラント一覧
                </v-card-subtitle>
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-card class="ma-5">
        <v-data-table  class="plant-list" 
        height="600"
        :headers="headers"
        :items="plantList"
        hide-default-footer
        >
        <template v-slot:item.now_gendo_uke="{item}">
            {{numberFormat(item.now_gendo_uke)}}<span class="text-caption pl-1">m3</span>
        </template>
        <template v-slot:item.now_kaido_stock="{item}">
            {{numberFormat(item.now_kaido_stock)}}<span class="text-caption pl-1">m3</span>
        </template>
        <template v-slot:item.actions="{item}">
            <v-btn class="btn-edit" small @click="clickView(item)">プラント詳細へ</v-btn>
        </template>
        <template v-slot:item.limit_date="{item}">
            <span :class="[item.count_date>0?'over':'']">{{item.limit_date}}</span>
        </template>

        </v-data-table>
        </v-card>
    </v-container>
</template>
<style lang="scss">
.plant-list{
    .over{
        color:red;
    }
}
.plant-list{
        th{
            background-color:#ccc !important;
        }
}
</style>
<script>
import { MESSAGE } from "../messages";
import { CONSTANT} from "../constant"
import InputDateFld from "../components/InputDateFld";
import DataGrid from "../components/DataGrid.vue"
const search = 0;
export default {
    name: "PlantList",
    components: { InputDateFld ,DataGrid},
    data() {
        return {
            plantList:[],
            headers:[
                {text:'ID', sortable: true, value:'plant_num', width:"80px", align:"center"},
                {text:'プラント名', sortable: true, value:'plant_name', width:"auto"},
                {text:'原料土受入可能量', sortable: true, value:'now_gendo_uke',align:"end",  width:"150px"},
                {text:'改良土ストック量', sortable: true, value:'now_kaido_stock',align:"end", width:"150px"},
                {text:'最終更新日', sortable: true, value:'last_upddate', width:"170px", align:"center"},
                {text:'更新期限', sortable:true, value:'limit_date', align:'center', width:'120px'},
                {text:'処理', sortable: false, value:'actions', width:"100", align:"center"},
            ]
        };
    },
    mounted() {

        this.getInit();
    },
    methods: {
        clickExpand() {
            this.expand != this.expand;
        },
        /**
         * 初期化
         */
        getInit() {
            // プラント一覧取得
            this.getPlantList();
        },
        getPlantList(){
            this.axios.post("/member/get-plant-list").then((res) => {
                if (res.data.resultCode == CONSTANT.RETURN_NORMAL ) {
                    this.plantList = res.data.resultData.plant_list;
                }
            });

        },
        clickView(plantInf){
            this.$store.commit('setPlantInqId', plantInf.plant_id);
            this.$store.commit('setReturnPath', "/plant");
            this.$router.push("/plant-view");
        },
        clickEdit(plantId) {
        },
    },
};
</script>

