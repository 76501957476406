<template>
    <v-footer color="teal" dark app v-if="isLogin">
      建設発生土プラント管理システム
    </v-footer>
</template>
<script>

export default {
    computed:{
        isLogin(){
            return this.$store.state.token==''?false:true;
        }
    }
}
</script>
