<template>
    <v-container fluid class="mt-0 pa-0">
	<v-card class="page-menu mb-3" rounded="0"  flat>
            <v-sheet
                elevation="2"
				rounded="0"
                height="40"
				max-width="100%"
                class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-2"
            >
                <v-icon class="white--text mb-2">mdi-database</v-icon>
                プラント管理
            </v-sheet>
            <v-card class="ma-5 rounded" >
                <v-system-bar color="gray" height="50">
                    <v-card-subtitle class="text-h6 font-weight-bold">
                        プラント詳細
                    </v-card-subtitle>
                    <v-spacer />
                    <v-btn class="mx-2 btn-close" @click="clickBack"
                        >戻る</v-btn
                    >
                    <v-btn class="mx-2 btn-edit" @click="clickEdit">編集</v-btn>
                    <v-btn class="btn-delete" @click="deletePlant">削除</v-btn>
                </v-system-bar>
            </v-card>
	</v-card>



        <v-card class="ma-5">
            <div class="pa-5 d-flex">
                <v-simple-table class="plant-tbl rounded-0">
                    <tr>
                        <th>プラント</th>
                        <td colspan="3">{{ plantInf.plant_name }}</td>
                    </tr>
                    <tr>
                        <th>住所・連絡先</th>
                        <td colspan="3">
                            <p>〒{{ plantInf.post }}</p>
                            <p class="pl-5">{{ plantInf.address3 }}</p>
                            <p class="pl-5">
                                <span class="text-caption">（担当）</span>
                                <span class="pl-2">{{
                                    plantInf.tantou_name
                                }}</span>
                            </p>
                            <p class="pl-5">
                                <span class="text-caption">（電話）</span>
                                <span class="pl-2" v-if="plantInf.tel">{{
                                    plantInf.tel
                                }}</span>
                                <span class="pl-2" v-if="!plantInf.tel"
                                    >　　　　　</span
                                >
                                <span class="pl-5 text-caption">（FAX）</span>
                                <span class="pl-2" v-if="plantInf.fax">{{
                                    plantInf.fax
                                }}</span>
                                <span class="pl-2" v-if="!plantInf.fax"
                                    >　　　　　</span
                                >
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th>ホームページ</th>
                        <td colspan="3">
                            <template v-if="plantInf.homepage">
                            <a :href="plantInf.homepage" target="_blank" rel="noopener noreferrer">{{ plantInf.homepage }}</a>
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <th>営業日</th>
                        <td colspan="3">{{ plantInf.sales_day }}</td>
                    </tr>
                    <tr>
                        <th>営業時間</th>
                        <td colspan="3">{{ plantInf.sales_time }}</td>
                    </tr>
                    <tr>
                        <th>稼働年月</th>
                        <td align="center">{{ plantInf.kado_ym }}</td>
                        <th>敷地面積</th>
                        <td align="right">
                            {{ numberFormat(plantInf.site_area) }}
                            <span class="text-caption pl-2">m2</span>
                        </td>
                    </tr>
                    <tr>
                        <th>原料土ヤード</th>
                        <td align="right">
                            <span v-if="plantInf.mate_area > 0">{{
                                numberFormat(plantInf.mate_area)
                            }}</span>
                            <span class="text-caption pl-2">m2</span>
                        </td>
                        <th>最大ストック量</th>
                        <td align="right">
                            <span v-if="plantInf.mate_stock > 0">{{
                                numberFormat(plantInf.mate_stock)
                            }}</span>
                            <span class="text-caption pl-2">m3</span>
                        </td>
                    </tr>
                    <tr>
                        <th>改良土ヤード</th>
                        <td align="right">
                            <span v-if="plantInf.impsoil_area > 0">{{
                                numberFormat(plantInf.impsoil_area)
                            }}</span>
                            <span class="text-caption pl-2">m2</span>
                        </td>
                        <th>最大ストック量</th>
                        <td align="right">
                            <span v-if="plantInf.impsoil_stock > 0">{{
                                numberFormat(plantInf.impsoil_stock)
                            }}</span>
                            <span class="text-caption pl-2">m3</span>
                        </td>
                    </tr>
                    <tr>
                        <th>土質改良能力</th>
                        <td colspan="3">
                            <span class="mx-3">
                                <span v-if="plantInf.impsoil_ability_h > 0">{{
                                    plantInf.impsoil_ability_h
                                }}</span>
                                <span v-if="plantInf.impsoil_ability_h <= 0"
                                    >--------</span
                                >
                                <span class="text-caption pl-1">m3/時</span>
                            </span>
                            <span class="mx-3">
                                <span v-if="plantInf.impsoil_ability_d > 0">{{
                                    plantInf.impsoil_ability_d
                                }}</span>
                                <span v-if="plantInf.impsoil_ability_d <= 0"
                                    >--------</span
                                >
                                <span class="text-caption pl-1">m3/日</span>
                            </span>
                            <span class="mx-3">
                                <span v-if="plantInf.impsoil_ability_y > 0">{{
                                    plantInf.impsoil_ability_y
                                }}</span>
                                <span v-if="plantInf.impsoil_ability_y <= 0"
                                    >--------</span
                                >
                                <span class="text-caption pl-1">万m3/年</span>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th>土質改良材種類</th>
                        <td colspan="3">
                            <div class="d-inline-flex align-center">
                                <div
                                    v-for="(item, i) in kairyoZaiList"
                                    :key="i"
                                >
                                    <v-checkbox
                                        v-model="kairyoZai"
                                        class="mr-7"
                                        :label="item.kubun_value1"
                                        :value="item.kubun_cd"
                                        dense
                                        readonly
                                        hide-details=""
                                    />
                                </div>
                                <span v-if="plantInf.ukeire_zai_sonota != ''"
                                    >({{ plantInf.ukeire_zai_sonota }})</span
                                >
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>受入土質</th>
                        <td colspan="3">
                            <div class="d-inline-flex">
                                <div v-for="(item, i) in ukeireDoList" :key="i">
                                    <v-checkbox
                                        v-model="ukeireDo"
                                        class="mr-7"
                                        :label="item.kubun_value1"
                                        :value="item.kubun_cd"
                                        dense
                                        readonly
                                        hide-details=""
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>その他受入条件</th>
                        <td colspan="3">{{ plantInf.gendo_uke_jyoken }}</td>
                    </tr>
                </v-simple-table>
                <div class="ml-5">
                    <div class="latery-btn mb-2">
                        <span
                            class="
                                ml-2
                                text-subtitle-1
                                red--text
                                font-weight-bold
                            "
                            >最新情報</span
                        >
                        <v-btn
                            depressed
                            elevation="2"
                            height="32"
                            color="primary"
                            @click="openLatelyDialog"
                            >最新情報編集</v-btn
                        >
                    </div>
                    <v-simple-table class="plant-tbl small-tbl">
                        <tr>
                            <th>更新日</th>
                            <td align="center">{{ plantInf.last_upddate }}</td>
                        </tr>
                        <tr>
                            <th>原料土受入可能量<br />（地山m3）</th>
                            <td align="right">
                                {{ numberFormat(gendoUke) }}&nbsp;m3
                            </td>
                        </tr>
                        <tr>
                            <th>改良土ストック量<br />（ほぐしm3）</th>
                            <td align="right">
                                {{ numberFormat(kaidoStock) }}&nbsp;m3
                            </td>
                        </tr>
                    </v-simple-table>
                </div>
            </div>

            <div class="pa-5">
                <div class="plant-subtitle">料金情報</div>
                <v-simple-table class="plant-tbl">
                    <tr>
                        <th></th>
                        <td style="background-color: #555; color: white">
                            HP　一覧掲載品目
                        </td>
                        <td style="background-color: #555; color: white">
                            料金
                        </td>
                    </tr>
                    <tr>
                        <th>原料土</th>
                        <td>{{ plantInf.hp_gendo_uke_name }}</td>
                        <td align="right">
                            {{
                                numberFormat(plantInf.hp_gendo_uke_kingaku)
                            }}円　　/地山 m3
                        </td>
                    </tr>
                    <tr>
                        <th>改良土</th>
                        <td>{{ plantInf.hp_kaido_name }}</td>
                        <td align="right">
                            {{
                                numberFormat(plantInf.hp_kaido_kingaku)
                            }}円　/ほぐし m3
                        </td>
                    </tr>
                    <tr>
                        <th>PDF料金表</th>
                        <td colspan="2">
                            <div class="d-flex d-inline-flex">
                          <v-radio-group class="ml-2" v-model="plantInf.pdf_flg" hide-details="auto" row>
  
              <v-radio
                                        v-for="item in pdfFileKbn"
                                        :key="item.value"
                                        :value="item.value"
                                        class="mr-7"
                                        :label="item.label"
                                        dense
                                        readonly
                                        hide-details=""
                                />
                                </v-radio-group>
                            </div>
                            <div>
                                <ryokin-file
                                    ignore_flg="true"
                                    title="料金表をダウンロードする"
                                    not_title="料金表はアップロードされていません"
                                    :plant_id="plantInf.plant_id"
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>申込み</th>
                        <td  colspan="2">
                            <div>
                            <v-radio-group class="ml-2" v-model="plantInf.moshikomi_kbn" hide-details="auto" row>
                                    <v-radio
                                        v-for="item in moshikomiKbn"
                                        :key="item.value"
                                        :value="item.value"
                                        class="mr-7"
                                        :label="item.label"
                                        dense
                                        readonly
                                        hide-details=""
                                />
                                </v-radio-group> 
                            </div>
                            <div v-if="plantInf.moshikomi_kbn == '1'">
                                <a
                                    class="pl-4"
                                    :href="plantInf.moshikomi_url"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >{{ plantInf.moshikomi_url }}</a
                                >
                            </div>
                            <div v-else-if="plantInf.moshikomi_kbn == '2'">
                                <moshikomi-file
                                    ignore_flg="true"
                                    title="申込み用紙をダウンロードする"
                                    not_title="申込み用紙はアップロードされていません"
                                    :plant_id="plantInf.plant_id"
                                />
                            </div>
                            <div v-else>
                                表示しない
                            </div>

                        </td>
                    </tr>
                </v-simple-table>
            </div>

            <div class="pa-5">
                <div class="plant-subtitle">改良土品質管理基準（試験頻度）</div>
                <v-simple-table class="plant-tbl">
                    <tr>
                        <th rowspan="3">力学的指数</th>
                        <td class="cell-title">CBR</td>
                        <td>{{ plantInf.manage_cbr }}</td>
                        <td class="cell-title">含水比</td>
                        <td>{{ plantInf.manage_gansui }}</td>
                    </tr>
                    <tr>
                        <td class="cell-title">一軸圧縮強度</td>
                        <td>{{ plantInf.manage_itijiku }}</td>
                        <td class="cell-title">pH</td>
                        <td>{{ plantInf.manage_ph }}</td>
                    </tr>
                    <tr>
                        <td class="cell-title">コーン指数</td>
                        <td>{{ plantInf.manage_cone }}</td>
                        <td class="cell-title">土壌環境基準</td>
                        <td>{{ plantInf.manage_dojyou }}</td>
                    </tr>
                    <tr>
                        <th rowspan="2">粒径</th>
                        <td class="cell-title">最大粒径</td>
                        <td>{{ plantInf.manage_ryukei }}</td>
                        <td class="cell-title">その他</td>
                        <td>{{ plantInf.manage_etc }}</td>
                    </tr>
                    <tr>
                        <td class="cell-title">細粒分</td>
                        <td>{{ plantInf.manage_sairyu }}</td>
                        <td colspan="2"></td>
                    </tr>
                </v-simple-table>
            </div>
            <div class="pa-5">
                <div class="plant-subtitle">保有重機と台数</div>

                <v-simple-table class="plant-tbl">
                    <tr>
                        <td>&nbsp;{{ plantInf.jyuuki_daisuu }}</td>
                    </tr>
                </v-simple-table>
            </div>
            <div class="pa-5">
                <div class="plant-subtitle">建設発生土実績・改良土出荷実績</div>

                <table class="plant-tbl">
                    <colgroup>
                        <col width="50" />
                    </colgroup>
                    <tr>
                        <th width="100" align="center">年度</th>
                        <td class="cell-title" align="center">
                            発生土受入量　万地山m3
                        </td>
                        <td class="cell-title" aiign="center">
                            改良土出荷量　万ほぐしm3
                        </td>
                    </tr>
                    <tr v-for="(item, index) in jituseki" :key="index">
                        <td align="center">{{ item.nendo }}&nbsp;年</td>
                        <td align="right">
                            {{ numberFormat(item.hassei_gendo) }}&nbsp;m3
                        </td>
                        <td align="right">
                            {{ numberFormat(item.shuka_kairyou) }}&nbsp;m3
                        </td>
                    </tr>
                </table>
            </div>
        </v-card>
        <dlg-lately
            ref="refLately"
            :plantId="Number(plantId)"
            :gendoUkeProp="Number(gendoUke)"
            :kaidoStockProp="Number(kaidoStock)"
            @update-lately="reloadLately($event)"
        ></dlg-lately>
    </v-container>
</template>
<style lang="scss" scoped>
.plant-tbl {
    border: 1px solid #ccc;
    width: 800px;
}
.small-tbl {
    width: 400px !important;
}
.small-tbl th {
    width: 150px;
}
.plant-tbl th {
    border: 1px solid #ccc;
    padding-left: 5px;
    background-color: #555;
    color: white;
    font-weight: normal;
    width: 200px;
    padding: 5px 25px 5px 25px;
    font-size: 0.8em;
    text-align: left;
    /*text-align-last: justify;*/
}
.plant-tbl td {
    border: 1px solid #ccc;
    padding: 5px;
}
.plant-tbl .cell-title {
    background-color: #555;
    width: 120px;
    color: white;
        padding: 5px 15px 5px 15px;
    font-size: 0.8em;
}
.plant-subtitle {
    height: 30px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    font-weight: bold;
}
.plant-tbl p {
    margin: 0px;
}
$text-field-line-height: 10px;
$text-field-border-radius: 20px;
.col-title {
    border: 1px solid #ccc;
    width: 200px;
    display: flex;
    align-items: center;
}
.col-content {
    border: 1px solid #ccc;
}
.box-parts {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    .box-parts-title {
        font-size: 13pt;
        font-weight: 800;
    }
}
.latery-btn {
    display: flex;
    justify-content: space-between;
}
.page-head{
  position: fixed;
  top: 10;
  width: 86%;
  background: #333;
  color: #fff;
  z-index: 1;
  background-color:white
}
.page-body{
  top: 120px;
    z-index: 0;
    margin-bottom: 200px;
}
</style>
<script>
import { MESSAGE } from "../messages";
import { CONSTANT } from "../constant";
import moment from "moment";
import DlgLately from "../components/dialog/DlgLately.vue";
import RyokinFile from "../components/RyokinFile.vue";
import MoshikomiFile from "../components/MoshikomiFile.vue";
export default {
    name: "PlantView",
    components: { DlgLately, RyokinFile, MoshikomiFile },
    data() {
        return {
            expand: true,
            plantId: "",
            plantInf: {},
            ukeireDoList: [],
            ukeireDo: [],
            kairyoZaiList: [],
            kairyoZai: [],
            jituseki: [],
            gendoUke: 0,
            kaidoStock: 0,
            pdfFileKbn:[
                {label:'ＰＤＦ表示',value:1},
                {label:'表示しない',value:0},
            ],
            moshikomiKbn:[
                {label:'ＵＲＬ表示',value:1},
                {label:'ＰＤＦ表示',value:2},
                {label:'表示しない',value:0},
            ]
        };
    },
    computed:{
        isPdf(){
            return (this.plantInf.pdf_flg==1)?true:false;
        }
    },
    mounted() {
        this.plantId = this.$store.state.plantInqId;
        // 受入土質種取得
        this.getMstData();

        // get Plants detail information
        this.getPlantInfo(this.plantId);
    },
    methods: {
        async getPlantInfo(plant_id) {
            //Get Plant All Data
            const res = await this.axios.post("/member/plant-get", {
                plant_id: plant_id,
            });
            if (res.data.resultCode == CONSTANT.RETURN_NORMAL) {
                this.plantInf = res.data.resultData.plantinfo;
                this.gendoUke = this.plantInf.now_gendo_uke;
                this.kaidoStock = this.plantInf.now_kaido_stock;
                this.ukeireDo = res.data.resultData.ukeire_do;
                this.kairyoZai = res.data.resultData.kairyozai;
                this.jituseki = res.data.resultData.jituseki;
            }
        },
        openMemberDetail() {
            this.$store.commit("setMemberId", this.memberInf.member_id);
            this.$router.push({
                name: "MemberInqury",
                params: { type: "Plant" },
            });
        },
        getMstData() {
            this.axios.post("/member/plant-edit-init").then((res) => {
                if (res.data.resultCode == CONSTANT.RETURN_NORMAL) {
                    this.ukeireDoList = res.data.resultData.ukeire_do;
                    this.kairyoZaiList = res.data.resultData.kairyo_zai;
                }
            });
        },
        openLatelyDialog() {
            this.$refs.refLately.open();
        },
        reloadLately(updInfo) {
            this.plantInf.last_upddate = updInfo.upddate;
            this.gendoUke = updInfo.gendoUke;
            this.kaidoStock = updInfo.kaidoStock;
        },
        clickBack() {
            if(this.$store.state.returnPath!=''){
                this.$router.push(this.$store.state.returnPath);
            }
            // if (this.$route.params.hasOwnProperty("type")) {
            //     this.$router.push({ name: "PlantList" });
            //     //    if(this.$route.params.type == 'Member'){
            //     //        this.$router.push({ name: 'MemberInqury', params: { type: 'Plant' }});
            //     //    }else if(this.$route.params.type == 'Map'){
            //     //        this.$router.push({ name: 'PlantMap' });
            //     //    }
            // } else {
            //     this.$router.push({
            //         name: "PlantList",
            //         params: { type: "PlantView" },
            //     });
            // }
        },
        clickEdit() {
            this.$store.commit('setPlantInqId', this.plantId);
            this.$router.push({
                name: "PlantEdit",
                params: { mode: CONSTANT.MODE_UPDATE },
            });
        },
        async deletePlant() {
            // 確認メッセージ
            let res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R003, "プラント情報")
            );
            if (!res) {
                return;
            }

            this.axios
                .post("/member/plant-delete", { plant_id: this.plantId })
                .then((res) => {
                    if (res.data.resultCode == CONSTANT.RETURN_NORMAL) {
                        this.swalToast(
                            MESSAGE.formatByArr(MESSAGE.I003, "プラント情報")
                        );
                        this.$router.push({ name: "PlantList" });
                    }
                });
        },
    },
};
</script>
